import React, { useContext, useState } from "react";
import { Product, ProductAccount } from "../../models/Product";
import { expiration_message, isEmpty } from "../../scripts/util";
import { displayFormattedText } from "../../utils/Format";
import moment from "moment/moment";
import { LoginCredentials } from "../ProductPage/LoginCredentials";
import {
  ANALYTICS_EVENT,
  eventBundleProperties,
  EVENT_TYPE,
  productDetailsBundle,
  sendAnalyticsData,
  VISIT_TIMEFRAME,
} from "../../scripts/analytics";
import { AuthContext } from "../../context/AuthContext";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export function ProductAccountSuccessPage({
  product,
  productAccount,
}: {
  product: Product;
  productAccount: ProductAccount;
}): JSX.Element {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const expiration = moment.utc(productAccount.expiration).local();
  const duration = moment.duration(expiration.diff(moment.now()));
  const sendVisitProductWebsiteEvent = () =>
    sendAnalyticsData(ANALYTICS_EVENT.ON_SUBMIT_GO_TO_PRODUCT_SITE, {
      ...eventBundleProperties(user, EVENT_TYPE.UA),
      ...productDetailsBundle(product, "ACCESSIBLE"),
      price: product.retailPrice,
      url: product.url,
      timeframe: VISIT_TIMEFRAME.POSTACCESS,
    });

  return (
    <div>
      <h2 id="success-title-text">Success! We're happy you're here.</h2>
      <div id="success-login-details">
        <p>Click below to auto-login in {product.name}</p>
        {!isEmpty(product.additionalTerms) &&
          displayFormattedText(product.additionalTerms)}
      </div>

      <Row>
        <Col md={8} className="mb-3">
          <p id="access-expiration">{expiration_message(duration)}</p>
          {productAccount.credentialWhitelisted || isMobile ? (
            <LoginCredentials productAccount={productAccount} />
          ) : (
            ""
          )}
          <div id="success-button-div">
            <button
              className={`btn btn-primary${loading ? " is-loading" : ""}`}
              disabled={loading}
              id="success-button-login"
              onClick={() => {
                if (loading) {
                  return;
                }

                setLoading(true);
                sendVisitProductWebsiteEvent();

                const checkProductSessionDataCookiePresence = (ms: number) => {
                  setTimeout(() => {
                    const cookiePresent =
                      document.cookie.indexOf(
                        `${product.name.toLowerCase()}__synced=`
                      ) > -1;

                    if (cookiePresent) {
                      setLoading(false);

                      if (productAccount.credentialWhitelisted || isMobile) {
                        window.open(product.url, "_blank");
                      } else {
                        // Wipe any possibly existing browser session data from that product's website
                        window.postMessage({
                          eventType: "keye_clear_product_session_data",
                          product: product.name,
                          origin: product.origin,
                        }, "*");

                        // Wait a little bit to make sure any previous session data is gone
                        setTimeout(() => {
                          // Make the extension set session data for that product's website
                          window.postMessage({
                            eventType: "login",
                            productId: product.id,
                            product: product.name,
                            url: product.url,
                            currentAccess: {
                              productAccount: {
                                username: productAccount.username,
                                password: productAccount.password,
                                expiration: productAccount.expiration,
                              },
                            },
                          }, "*");
                        }, 100);
                      }
                    } else {
                      if (ms < 20000) {
                        checkProductSessionDataCookiePresence(ms + 100);
                      } else {
                        // Timeout, something went wrong
                        setLoading(false);
                      }
                    }
                  }, 100);
                };

                checkProductSessionDataCookiePresence(0);
              }}>
              {loading
                ? "Unlocking your access"
                : `Auto-login in ${product.name}`}
            </button>
          </div>
        </Col>
        <Col md={4}>
          <img src="/images/welcome_message.png" alt="Welcome!" />
        </Col>
      </Row>
    </div>
  );
}
